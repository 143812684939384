<template>
    <h1 class="h1" style="margin: 0;">股票打粉后台登录</h1>
    <div class="login-form">
        <div class="close"> </div>
        <div class="head-info">
            <label class="lbl-1"> </label>
            <label class="lbl-2"> </label>
            <label class="lbl-3"> </label>
        </div>
        <div class="clear"> </div>
        <div class="avtar"><img class="img" src="../assets/images/avtar.png" /></div>
        <form>
            <div style="display: flex;align-items: baseline;">
                <input v-model="email" type="text" class="text input" placeholder="邮箱">
                <el-link @click="sendCode" :disabled="!email || emailSendCodeLoading">
                    {{ emailSendCodeLoading ? '正在发送中' : '发送验证码' }}
                </el-link>
            </div>
            <div class="key"><input v-model="code" type="text" class="input" placeholder="验证码"></div>
        </form>
        <div class="signin"><input class="input" @click="login" type="submit" value="登录"></div>
    </div>
</template> 
<script setup>
import { ref } from 'vue';
import { loginSendEmailCode, saveLoginToken, emailLogin } from '../utils/api';
import { router } from '../main';

const emailSendCodeLoading = ref(false);
const emailLoginLoading = ref(false);
const email = ref('');
const code = ref('');

// 发送验证码
async function sendCode() {
    emailSendCodeLoading.value = true;
    try {
        const data = await loginSendEmailCode(email.value);
        if (data.status !== 200) {
            throw await data.json();
        }
        alert('发送成功');
    } catch (err) {
        console.error(err);
        alert(err.message);
    } finally {
        emailSendCodeLoading.value = false;
    }
}

async function login() {
    emailLoginLoading.value = true;
    try {
        const data = await emailLogin(email.value, code.value);
        if (data.status !== 200) {
            throw await data.json();
        }
        const token = await data.text();
        saveLoginToken(token);
        router.push('/');
    } catch (err) {
        console.error(err);
        alert(err.message);
    } finally {
        emailLoginLoading.value = false;
    }

}

</script>
<style scoped>
@import url('../assets/css/style.css');
</style>