<!--
 * @Author: ZhangSan lx1787624543@hotmail.com
 * @Date: 2023-11-27 12:27:17
 * @LastEditors: ZhangSan lx1787624543@hotmail.com
 * @LastEditTime: 2023-11-27 13:01:43
 * @FilePath: \stock-manage-vue-v2\src\components\CopyAlert.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <el-popover placement="top-start" title="点击按钮可以复制" trigger="hover"
        @hide="copyOk = false">
        <template #reference>
            <slot></slot>
        </template>
        <template #default>
            <el-alert :title="copyOk ? '复制成功!!' : '还未复制'" :type="copyOk?'success':'info '" />
            <el-button @click="copy" type="warning" size="small" >点击复制</el-button>
        </template>
    </el-popover>
</template>
<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps({
    content: [String, Boolean, Number],
});

const copyOk = ref(false);

function copy() {
    navigator.clipboard.writeText(props.content);
    copyOk.value = true;
}

</script>