<template>
    <el-dialog v-model="dialogVisible" :title="fromTitle" width="750px" :before-close="handleClose">
        <el-form :model="formConfig" label-width="120px">
            <el-form-item label="名称">
                <el-input autosize type="textarea" v-model="formConfig.title" />
            </el-form-item>
            <el-form-item label="备注">
                <el-input autosize type="textarea" v-model="formConfig.notes" />
            </el-form-item>
            <el-form-item label="广告ID">
                <el-input autosize type="textarea" v-model="formConfig.configId" />
            </el-form-item>
            <el-form-item label="转换事件发送参数">
                <el-input v-model="formConfig.conversionSendTo" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitConfig">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ElMessageBox } from 'element-plus';
import { addGoogleAd, editGoogleAd, EMPTY_GOOGLE_AD } from '../utils/api';
import { defineEmits, ref, defineExpose } from 'vue';

const emits = defineEmits(['onSubmit']);
const dialogVisible = ref(false)
const formConfig = ref({ ...EMPTY_GOOGLE_AD });


function submitConfig() {
    const fromData = formConfig.value;
    (fromData.id ? editGoogleAd(fromData) : addGoogleAd(fromData))
        .then((response) => fromData.id ? response.text() : response.json())
        .then((fromReult) => {
            const error = fromReult.message;
            ElMessageBox.alert(error || '成功', '提示', {
                // if you want to disable its autofocus
                // autofocus: false,
                confirmButtonText: '我知道了',
                callback: () => {
                    if (!error) {
                        dialogVisible.value = false;
                        emits('onSubmit');
                    }
                },
            })
        });
}

function openFormDialogVisible({ data = EMPTY_GOOGLE_AD }) {
    dialogVisible.value = true;
    formConfig.value = data;
}

defineExpose({ openFormDialogVisible });
</script>