import { router } from '../main';

// 接口短点地址
// export const API_HOST = "http://127.0.0.1:8082"; // 开发地址
// export const API_HOST = "https://faa.youmiq.com"; // 生产地址

// export const API_HOST = "https://a.kr.ious.cfd"; // 生产地址，v2
export const API_HOST = "https://www.ujko.xyz"; // 生产地址，v3
// JSON
const ContentJSONHeader = {
  "Content-Type": "application/json",
};

// Google Ad
const GoogleManageAdAPIPath = "/admin/google_gtag";
// Google Ad
const GoogleManageFirebaseAPIPath = "/admin/google/firebase";
// Google Ad Group
const GoogleManageAdGroupAPIPath = "/admin/google_gtag/group";

export function createLoginErr() {
  return { message: '需要登录', needLogin: true };
}

const TokenKey = 'user-token';
export function getLoginToken() {
  const key = localStorage[TokenKey];
  if (!key) {
    throw createLoginErr();
  }

  return key;
}

// 登录令牌
export function saveLoginToken(token) {
  localStorage[TokenKey] = token;
}


export function getUserAuthHeader() {
  try {
    return { 'Authentication': getLoginToken() };
  } catch (e) {
    if (e.needLogin) {
      router.push('/login');
    }
    throw e;
  }
}


export function needLoginFetch(fetchResp) {
  if (fetchResp.code === '100098') {
    router.push('/login');
    throw createLoginErr();
  }

  return fetchResp;
}

/**
 * 获取广告链接
 */
export function getAdLinkList(body = {}) {
  return fetch(`${API_HOST}/admin/config/ad_links`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      ...getUserAuthHeader(),
      ...ContentJSONHeader,
    },
  });
}


/**
 * 获取广告链接
 */
export function loginSendEmailCode(email) {
  return fetch(`${API_HOST}/admin/user/send-email-${email}/logo-code`, {
    method: 'POST'
  });
}


/**
 * 获取广告链接
 */
export function emailLogin(email, code) {
  return fetch(`${API_HOST}/admin/user/email-${email}/login/${code}`, {
    method: 'POST'
  });
}

/**
 * all data
 * @param {*} path API path
 * @returns List
 */
export function getAllTripartite(path, titleFilter) {
  return fetch(`${API_HOST}${path}/all?titleFilter=${titleFilter || ""}`, {
    headers: {
      ...getUserAuthHeader(),
    },
  });
}

/**
 * all data
 * @param {*} path API path
 * @returns List
 */
export function delKeyAllTripartite(path, key) {
  return fetch(`${API_HOST}${path}/${key}`, {
    method: "delete",
    headers: {
      ...getUserAuthHeader(),
    },
  });
}

/**
 * Add
 * @param {*} path API path
 * @returns List
 */
export function addTripartite(path, entity) {
  return fetch(`${API_HOST}${path}`, {
    method: "put",
    headers: {
      ...getUserAuthHeader(),
      ...ContentJSONHeader,
    },
    body: JSON.stringify(entity),
  });
}

/**
 * Edit by id
 * @param {*} path API path
 * @returns List
 */
export function editTripartite(path, entity) {
  return fetch(`${API_HOST}${path}`, {
    method: "post",
    headers: {
      ...getUserAuthHeader(),
      ...ContentJSONHeader,
    },
    body: JSON.stringify(entity),
  });
}

/**
 * 添加广告链接
 */
export function addAdLink(body = {}) {
  return fetch(`${API_HOST}/admin/config/add`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      ...getUserAuthHeader(),
      "Content-Type": "application/json",
    },
  });
}

/**
 * 修改广告链接
 */
export function editAdLink(body = {}) {
  return fetch(`${API_HOST}/admin/config/uptById`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      ...getUserAuthHeader(),
      "Content-Type": "application/json",
    },
  });
}

export const EMPTY_PAGE = responseEmpty({
  records: [],
  total: 0,
  size: 10,
  current: 1,
  orders: [],
  optimizeCountSql: true,
  searchCount: true,
  countId: null,
  maxLimit: null,
  pages: 0,
});

export const EMPTY_LINK_COFIG = {
  title: null, // 备注
  url: null, // 群链接
  content: null, // 访问链接
  configKey: 98,
  configId: null,
  type: 0,
  metaPixelId: null,
  firebaseEnable: false,
  metaPixelEnable: false,
  gtagEnable: false,
  gtagId: null,
  firebaseId: null,
  jumpLinks: [],
  directJump: false,
  useGoogleTagGroup: false,
  googleTagGroupId: null
};

export const JumpLinksItem = {
  url: null, // 备注
  width: 1, // 访问链接
  enable: true,
};

export const EMPTY_GOOGLE_AD = {
  configId: null,
  conversionSendTo: null,
};

export const EMPTY_GOOGLE_AD_GROUP = {
  googleTagId: [],
};

export const EMPTY_GOOGLE_Firebase = {
  apiKey: null,
  authDomain: null,
  projectId: null,
  storageBucket: null,
  messagingSenderId: null,
  appId: null,
  measurementId: null,
};

export function responseEmpty(body) {
  return {
    body,
    code: "0",
    message: "加载中",
    success: false,
  };
}

/**
 * all data
 * @returns List
 */
export function getAllGoogleAd(titleFilter) {
  return getAllTripartite(GoogleManageAdAPIPath, titleFilter);
}

/**
 * all data
 * @returns List
 */
export function delKeyGoogleAd(key) {
  return delKeyAllTripartite(GoogleManageAdAPIPath, key);
}

/**
 * Add
 * @param {*} entity API path
 * @returns List
 */
export function addGoogleAd(entity) {
  return addTripartite(GoogleManageAdAPIPath, entity);
}

/**
 * Edit by id
 * @param {*} entity API path
 * @returns List
 */
export function editGoogleAd(entity) {
  return editTripartite(GoogleManageAdAPIPath, entity);
}

/**
 * all data
 * @returns List
 */
export function getAllGoogleFirebase(titleFilter) {
  return getAllTripartite(GoogleManageFirebaseAPIPath, titleFilter);
}

/**
 * all data
 * @returns List
 */
export function deleteKeyGoogleFirebase(key) {
  return delKeyAllTripartite(GoogleManageFirebaseAPIPath, key);
}

/**
 * Add
 * @param {*} entity API path
 * @returns List
 */
export function addGoogleFirebase(entity) {
  return addTripartite(GoogleManageFirebaseAPIPath, entity);
}

/**
 * Edit by id
 * @param {*} entity API path
 * @returns List
 */
export function editGoogleFirebase(entity) {
  return editTripartite(GoogleManageFirebaseAPIPath, entity);
}



/**
 * all data
 * @returns List
 */
export function getAllGoogleAdGroup(titleFilter) {
  return getAllTripartite(GoogleManageAdGroupAPIPath, titleFilter);
}

/**
 * all data
 * @returns List
 */
export function delKeyGoogleAdGroup(key) {
  return delKeyAllTripartite(GoogleManageAdGroupAPIPath, key);
}

/**
 * Add
 * @param {*} entity API path
 * @returns List
 */
export function addGoogleAdGroup(entity) {
  return addTripartite(GoogleManageAdGroupAPIPath, entity);
}

/**
 * Edit by id
 * @param {*} entity API path
 * @returns List
 */
export function editGoogleAdGroup(entity) {
  return editTripartite(GoogleManageAdGroupAPIPath, entity);
}
