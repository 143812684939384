<template>
    <el-row :gutter="20" justify="center" align="middle">
        <el-col :span="20">
            <el-select v-model="value" placeholder="请选择" v-bind="props.select">
                <el-option v-for="item in list" :key="item.id" :label="item.title" :value="item.id" />
            </el-select>
        </el-col>
        <el-col :span="1" v-if="!moreHide">
            <el-button type="text" @click="requestAllGoogleAd">
                <el-icon>
                    <Refresh />
                </el-icon>
            </el-button>
        </el-col>
        <el-col :span="2" v-if="!moreHide">
            <el-button type="text" @click="fromRef?.openFormDialogVisible">添加</el-button>
        </el-col>
    </el-row>

    <GoogleGTagFrom @on-submit="requestAllGoogleAd" ref="fromRef" />
</template>
<script setup>
import { Refresh } from '@element-plus/icons-vue';
import GoogleGTagFrom from './GoogleGTagFrom.vue';

import { getAllGoogleAdGroup } from '../utils/api';
import { ref, defineProps, defineEmits, watch, computed } from 'vue';



const props = defineProps(['modelValue', 'select']);

const moreHide = computed(() => {
    if (props.select && props.select.disabled) {
        return true;
    }
    return false;
});

const list = ref([]);
const fromRef = ref();
const value = ref(props.modelValue);

const emits = defineEmits(['update:modelValue']);

watch(value, (val) => {
    emits('update:modelValue', val);
});

function requestAllGoogleAd() {
    getAllGoogleAdGroup()
        .then(res => res.json())
        .then((body) => list.value = body);
}

requestAllGoogleAd();
</script>