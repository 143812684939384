<template>
    <CopyAlert :content="props.url">
        <el-link :href="props.url" target="_blank" :type="props.type">{{ props.url }}</el-link>
    </CopyAlert>
</template>
<script setup>
import { defineProps } from 'vue';
import CopyAlert from './CopyAlert.vue';

const props = defineProps({
    url: String,
    type: String
});

</script>