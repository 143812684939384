<template>
    <el-card class="box-card">
        <template #header>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="名称">
                    <el-input v-model="titleFilterRef" placeholder="自定义名称" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="openFormDialogVisible({})">
                        <el-icon>
                            <DocumentAdd />
                        </el-icon>新增
                    </el-button>
                </el-form-item>
            </el-form>
        </template>
        <el-table :data="list" style="width: 100%">
            <el-table-column prop="id" label="编号" width="100" />
            <el-table-column label="标题" width="200">
                <template #default="scope">
                    <LinkOpen :url="scope.row.title" type="primary" />
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template #default="scope">
                    <LinkOpen :url="scope.row.notes" type="success" />
                </template>
            </el-table-column>
            <el-table-column label="谷歌应用参数" width="600">
                <template #default="scope">
                    <el-descriptions :column="4" size="small" :border="true">
                        <el-descriptions-item label="apiKey">{{ scope.row.apiKey }}</el-descriptions-item>
                        <el-descriptions-item label="authDomain">{{ scope.row.authDomain }}</el-descriptions-item>
                        <el-descriptions-item label="projectId">{{ scope.row.projectId }}</el-descriptions-item>
                        <el-descriptions-item label="storageBucket">{{ scope.row.storageBucket }}</el-descriptions-item>
                        <el-descriptions-item label="messagingSenderId">{{ scope.row.messagingSenderId
                        }}</el-descriptions-item>
                        <el-descriptions-item label="appId">{{ scope.row.appId }}</el-descriptions-item>
                        <el-descriptions-item label="measurementId">{{ scope.row.measurementId }}</el-descriptions-item>
                    </el-descriptions>
                </template>
            </el-table-column>

            <el-table-column label="添加时间">
                <template #default="scope">
                    <el-link type="info">{{ scope.row.createTime }}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="修改时间">
                <template #default="scope">
                    <el-link type="danger">{{ scope.row.uptTime }}</el-link>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="100">
                <template #default="scope">
                    <el-button @click="openFormDialogVisible({ data: scope.row })" type="text" size="small">编辑</el-button>
                    <el-popconfirm title="确定删除吗？" @confirm="deleteRow(scope.row)">
                        <template #reference>
                            <el-button style="color:red" type="text" size="small">删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </el-card>

    <GoogleFirebaseAppFrom @on-submit="requestAllGoogleAd" ref="fromRef" />
</template>
<script setup>
import { ref } from 'vue';
import { getAllGoogleFirebase, deleteKeyGoogleFirebase } from '../utils/api';
import { DocumentAdd } from "@element-plus/icons-vue";
import LinkOpen from './LinkOpen.vue';
import GoogleFirebaseAppFrom from './GoogleFirebaseAppFrom.vue';

// Filter as Like%
const titleFilterRef = ref(null);
const list = ref([]);
const fromRef = ref();

function requestAllGoogleAd() {
    getAllGoogleFirebase(titleFilterRef.value)
        .then(res => res.json())
        .then((body) => list.value = body);
}

requestAllGoogleAd();

function openFormDialogVisible(params) {
    fromRef.value.openFormDialogVisible(params);
}

async function deleteRow({ id }) {
    try {
        await deleteKeyGoogleFirebase(id);
        requestAllGoogleAd();
    } catch (err) {
        alert(err.message);
    }
}

const onSubmit = () => { requestAllGoogleAd() }
</script>