<template>
    <el-container>
        <el-header>
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
                @select="handleSelect">
                <el-menu-item index="0">
                    <img style="width: 100px" :src="logoImg" alt="Manage logo" />
                </el-menu-item>
                <div class="flex-grow"></div>
                <el-menu-item index="1">群链接管理</el-menu-item>
                <el-menu-item index="2">谷歌广告管理</el-menu-item>
                <el-menu-item index="3">谷歌统计管理</el-menu-item>
                <el-menu-item index="4">谷歌广告账户组</el-menu-item>
                <el-menu-item index="5">统计</el-menu-item>
            </el-menu>
        </el-header>
        <el-main>
            <GoogleGTag v-if="activeIndex === '2'" />
            <GoogleFirebaseApp v-if="activeIndex === '3'" />
            <GoogleGTagGroup v-if="activeIndex === '4'" />
            <iframe v-if="activeIndex === '5'" style="width: 100%;border: 0; background: #FFF;"
                :style="{ height: chatHeight }"
                :src="`./chat/index.html?token=${getLoginToken()}&host=${API_HOST}/`"></iframe>
            <el-card class="box-card" v-if="activeIndex === '1'">
                <template #header>
                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                        <el-form-item label="群链接">
                            <el-input v-model="formInline.chatURL" placeholder="以http开头" clearable />
                        </el-form-item>
                        <el-form-item label="像素ID">
                            <el-input v-model="formInline.pxId" placeholder="facebook广告位" clearable />
                        </el-form-item>
                        <el-form-item label="广告链接">
                            <el-input v-model="formInline.adPageURL" placeholder="以http开头" clearable />
                        </el-form-item>
                        <el-form-item label="备注">
                            <el-input v-model="formInline.notes" placeholder="自定义备注" clearable />
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="success" @click="openFormDialogVisible({})">
                                <el-icon>
                                    <DocumentAdd />
                                </el-icon>新增
                            </el-button>
                        </el-form-item>
                    </el-form>
                </template>
                <el-table :data="adListResult.body.records" style="width: 100%">
                    <el-table-column prop="configId" label="编号" width="100" />
                    <el-table-column prop="title" label="备注" />
                    <el-table-column label="群链接" width="300">
                        <template #default="scope">
                            <LinkOpen :url="scope.row.content"
                                :type="effectuateGroupLinkMore(scope.row) ? 'info' : 'primary'" />
                            <span v-if="effectuateGroupLinkMore(scope.row)" style="color:red">
                                已设置多群链接，
                                <el-button type="text" @click="openFormDialogVisible({ data: scope.row })">点击</el-button>
                                查看
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="对应访问链接">
                        <template #default="scope">
                            <LinkOpen :url="scope.row.url" type="success" />
                        </template>
                    </el-table-column>
                    <el-table-column label="像素ID">
                        <template #default="scope">
                            <LinkOpen :url="scope.row.metaPixelId" type="warning" />
                        </template>
                    </el-table-column>

                    <el-table-column label="直接访问">
                        <template #default="scope">
                            {{ scope.row.directJump ? '是' : '否' }}
                        </template>
                    </el-table-column>


                    <el-table-column label="三方信息" width="200">
                        <template #default="scope">
                            <el-switch :value="scope.row.metaPixelEnable" size="small" disabled
                                :active-text="`Facebook AD: ${scope.row.metaPixelId ?? '-'}`" /><br />
                            <el-switch :value="scope.row.gtagEnable" size="small" disabled
                                :active-text="`Google AD: ${scope.row.useGoogleTagGroup ? scope.row.googleTagGroupId + ':Group' : scope.row.gtagId ?? '-'}`" /><br />
                            <el-switch :value="scope.row.firebaseEnable" size="small" disabled
                                :active-text="`Google Firebase: ${scope.row.firebaseId ?? '-'}`" />
                        </template>
                    </el-table-column>

                    <el-table-column label="添加时间">
                        <template #default="scope">
                            <el-link type="info">{{ scope.row.createTime }}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="修改时间">
                        <template #default="scope">
                            <el-link type="danger">{{ scope.row.uptTime }}</el-link>
                        </template>
                    </el-table-column>

                    <el-table-column fixed="right" label="操作" width="100">
                        <template #default="scope">
                            <el-button @click="openFormDialogVisible({ data: scope.row })" type="text"
                                size="small">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-space wrap style="margin-top: 1em;">
                    <el-pagination v-model:current-page="formInline.current" v-model:page-size="formInline.size"
                        :page-sizes="[10, 20, 50, 100, 200, 300, 400]" layout="total, sizes, prev, pager, next, jumper"
                        :total="adListResult.body.total" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" />
                </el-space>
            </el-card>
        </el-main>
    </el-container>

    <el-dialog v-model="dialogVisible" :title="fromTitle" width="750px" :before-close="handleClose">
        <el-form :model="formConfig" label-width="120px">
            <el-form-item label="备注">
                <el-input autosize type="textarea" v-model="formConfig.title" />
            </el-form-item>
            <el-form-item label="访问链接">
                <el-input autosize type="textarea" v-model="formConfig.url" />
            </el-form-item>
            <el-form-item label="单群链接">
                <el-input autosize type="textarea" v-model="formConfig.content" />
                <el-alert title="如已设置多群组链接且有一个启用后此链接将失效" type="warning">
                </el-alert>
            </el-form-item>

            <el-form-item label="多群链接">
                <el-form :model="item" inline v-for="(item, i) in formConfig.jumpLinks" :key="i">
                    <el-form-item label="群链接">
                        <el-input autosize v-model="item.url" />
                    </el-form-item>
                    <el-form-item label="权重">
                        <el-input-number autosize v-model="item.weight" />
                    </el-form-item>
                    <el-form-item label="启用">
                        <el-switch v-model="item.enable" />
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input v-model="item.notes" />
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="delGrooupOne(i)">删除</el-button>
                    </el-form-item>
                </el-form>
                <el-button @click="addGrooupOne">添加一个群链接</el-button>
                <el-alert title="设置此值后单群链接会失效" type="warning"></el-alert>
            </el-form-item>

            <el-form-item label="像素ID">
                <el-switch v-model="formConfig.metaPixelEnable" active-color="#13ce66" inactive-color="#ff4949"
                    active-text="是否启用Facebook广告" />
                <el-input v-model="formConfig.metaPixelId" />
            </el-form-item>

            <el-form-item label="直接跳转">
                <el-switch v-model="formConfig.directJump" />
                <el-alert title="启用后不展示落地页直接跳转群链接" type="warning"></el-alert>
            </el-form-item>
            <el-form-item label="谷歌统计">
                <el-switch v-model="formConfig.firebaseEnable" active-color="#13ce66" inactive-color="#ff4949"
                    active-text="是否启用谷歌统计" />
                <GoogleFirebaseAppSelect v-model="formConfig.firebaseId" />
            </el-form-item>
            <el-form-item label="谷歌广告">
                <el-switch v-model="formConfig.gtagEnable" active-color="#13ce66" inactive-color="#ff4949"
                    active-text="是否启用谷歌广告" />
                <div style="width: 1em;"></div>
                <el-radio-group v-model="formConfig.useGoogleTagGroup">
                    <el-radio :label="true">使用谷歌广告账户组</el-radio>
                    <el-radio :label="false">单个谷歌账户</el-radio>
                </el-radio-group>
                <GoogleGTagGroupSelect v-if="formConfig.useGoogleTagGroup" v-model="formConfig.googleTagGroupId" />
                <GoogleGTagSelect v-if="!formConfig.useGoogleTagGroup" v-model="formConfig.gtagId" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="submitConfig">
                    确定
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import logoImg from '../assets/logo.png'
import { DocumentAdd } from "@element-plus/icons-vue";
import {
    getAdLinkList, EMPTY_PAGE, EMPTY_LINK_COFIG, addAdLink, editAdLink,
    API_HOST,
    JumpLinksItem, needLoginFetch, getLoginToken
} from "../utils/api"
import { ElMessageBox } from 'element-plus';
import LinkOpen from './LinkOpen.vue';

import GoogleGTag from './GoogleGTag.vue';
import GoogleGTagGroup from './GoogleGTagGroup.vue';
import GoogleFirebaseApp from './GoogleFirebaseApp.vue';
import GoogleGTagSelect from './GoogleGTagSelect.vue';
import GoogleGTagGroupSelect from './GoogleGTagGroupSelect.vue';
import GoogleFirebaseAppSelect from './GoogleFirebaseAppSelect.vue';

const activeIndex = ref('1')
const chatHeight = ref(document.body.clientHeight - 100 + 'px')
const dialogVisible = ref(false)
const formConfig = ref({ ...EMPTY_LINK_COFIG });
const formInline = reactive({
    chatURL: '',
    adPageURL: '',
    notes: '',
    current: 1, //分页
    size: 10 // 一页大小，默认值
});

const fromTitle = computed(() => `${formConfig.value.configId ? '修改' : '添加'}群链接配置`);
const handleSelect = (key, keyPath) => {
    console.log(key, keyPath);
    activeIndex.value = key;
}

function effectuateGroupLinkMore({ jumpLinks }) {
    if (!jumpLinks || !jumpLinks.length) return false;
    return jumpLinks.filter(it => it.enable).length > 0;
}

function addGrooupOne() {
    let jumpLinks = formConfig.value.jumpLinks || [];
    jumpLinks = [...jumpLinks, { ...JumpLinksItem }]
    formConfig.value = { ...formConfig.value, jumpLinks }
}

function delGrooupOne(index) {
    formConfig.value = { ...formConfig.value, jumpLinks: formConfig.value.jumpLinks.filter((_, i) => i !== index) }
}

function submitConfig() {
    const fromData = formConfig.value;
    (fromData.configId ? editAdLink(fromData) : addAdLink(fromData))
        .then((response) => response.json())
        .then((fromReult) => {
            console.log(fromReult);
            ElMessageBox.alert(fromReult.message, '提示', {
                // if you want to disable its autofocus
                // autofocus: false,
                confirmButtonText: '我知道了',
                callback: () => {
                    if (fromReult.success) {
                        dialogVisible.value = false;
                        onSubmit();
                    }
                },
            })
        });
}

function openFormDialogVisible({ data = EMPTY_LINK_COFIG }) {
    dialogVisible.value = true;
    formConfig.value = data;
}

function handleCurrentChange(v) {
    formInline.current = v;
    onSubmit();
}
function handleSizeChange(v) {
    formInline.size = v;
    onSubmit();
}


const adListResult = ref(EMPTY_PAGE);
async function fetchAdList() {
    await getAdLinkList(formInline).then((response) => response.json())
        .then((data) => adListResult.value = needLoginFetch(data));
}

fetchAdList();

const onSubmit = () => { fetchAdList() }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
