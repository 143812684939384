<template>
    <el-row :gutter="20" justify="center" align="middle">
        <el-col :span="20">
            <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in list" :key="item.id" :label="item.title" :value="item.id" />
            </el-select>
        </el-col>
        <el-col :span="1">
            <el-button type="text" @click="requestAllGoogleAd">
                <el-icon>
                    <Refresh />
                </el-icon>
            </el-button>
        </el-col>
        <el-col :span="2">
            <el-button type="text" @click="fromRef?.openFormDialogVisible">添加</el-button>
        </el-col>
    </el-row>

    <GoogleFirebaseAppFrom @on-submit="requestAllGoogleAd" ref="fromRef" />
</template>
<script setup>
import { Refresh } from '@element-plus/icons-vue';
import GoogleFirebaseAppFrom from './GoogleFirebaseAppFrom.vue';
import { getAllGoogleFirebase } from '../utils/api';
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps(['modelValue']);


const list = ref([]);
const fromRef = ref();
const value = ref(props.modelValue);

const emits = defineEmits(['update:modelValue']);

watch(value, (val) => {
    emits('update:modelValue', val);
});

function requestAllGoogleAd() {
    getAllGoogleFirebase()
        .then(res => res.json())
        .then((body) => list.value = body);
}

requestAllGoogleAd();
</script>